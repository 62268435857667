import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import { Navigation, Pagination } from 'swiper/modules';

const ServiceSlider = () => {
  const cdnUrl = process.env.REACT_APP_CDN_URL;

  return (
    <Swiper
      spaceBetween={10}
      slidesPerView={1}
      navigation
      pagination={{
        clickable: true,
      }}
      mousewheel
      keyboard
      modules={[Navigation, Pagination]}
      autoplay
      loop
    >
      <SwiperSlide>
        <div className="d-flex justify-content-between">
          <div
            className="slider-image-wrap"
            style={{ width: '50%', paddingLeft: '40px', paddingRight: '8px' }}
          >
            <img
              src={`${cdnUrl}/slider1_1.webp`}
              alt="slider1_1"
              className="slider-boxShadow"
            />
          </div>
          <div
            className="slider-image-wrap"
            style={{ width: '50%', paddingLeft: '8px', paddingRight: '40px' }}
          >
            <img
              src={`${cdnUrl}/slider1_2.webp`}
              alt="slider1_2"
              className="slider-boxShadow"
            />
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="d-flex justify-content-center align-items-center">
          <div
            className="slider-image-wrap"
            style={{ paddingLeft: '40px', paddingRight: '40px' }}
          >
            <img src={`${cdnUrl}/slider3.webp`} alt="slider3" />
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="d-flex justify-content-center align-items-center">
          <div
            className="slider-image-wrap"
            style={{ paddingLeft: '40px', paddingRight: '40px' }}
          >
            <img src={`${cdnUrl}/slider5.webp`} alt="slider5" />
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="d-flex justify-content-center align-items-center">
          <div
            className="slider-image-wrap"
            style={{ paddingLeft: '40px', paddingRight: '40px' }}
          >
            <img src={`${cdnUrl}/slider4.webp`} alt="slider4" />
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="d-flex justify-content-between">
          <div
            className="slider-image-wrap"
            style={{ width: '50%', paddingLeft: '40px', paddingRight: '8px' }}
          >
            <img
              src={`${cdnUrl}/slider2_1.webp`}
              alt="slider2_1"
              className="slider-boxShadow"
            />
          </div>
          <div
            className="slider-image-wrap"
            style={{ width: '50%', paddingLeft: '8px', paddingRight: '40px' }}
          >
            <img
              src={`${cdnUrl}/slider2_2.webp`}
              alt="slider2_2"
              className="slider-boxShadow"
            />
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};
export default ServiceSlider;
