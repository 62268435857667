import { Box, Button, FormControl, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { forgotPassword } from '../../Redux/Slice/authenticationSlice';
import {
  checkEmailValidity,
  generateRecaptchaToken,
} from '../../Utils/service';
import { ROUTE, ReCaptchaActionType } from '../../Enum';
import Loader from '../CommonComponent/loader';
import Footer from './footer';
import BackToLoginButton from './backToLoginButton';
import Header from './header';
import { StoreState } from '../../Redux/Interface';
import { ForgotPasswordFormValues } from './interface';

const ForgotPassword = () => {
  const [initialFormValue, setInitialFormValue] =
    useState<ForgotPasswordFormValues>({
      email: '',
    });
  const [isSubmit, setIsSubmit] = useState(false);
  const dispatch = useDispatch();
  const navigator = useNavigate();

  const { data, isLoading } = useSelector(
    (state: StoreState) => state.authentication,
  );

  const saveEntity = async (values: ForgotPasswordFormValues) => {
    dispatch(
      // @ts-expect-error Will fix this
      forgotPassword({
        ...values,
        recaptchaToken: await generateRecaptchaToken(
          ReCaptchaActionType.USER_FORGOT_PASSWORD,
        ),
      }),
    );
    setInitialFormValue(values);
  };

  useEffect(() => {
    if (data?.status === 200) {
      navigator(`/${ROUTE.RESET_PASSWORD_VERIFICATION}`, {
        state: { email: initialFormValue.email },
      });
    }
  }, [data]);

  return (
    <>
      <Loader loading={isLoading} />
      <Header />
      <BackToLoginButton />
      <Box className="authentication-form-wrap">
        <Box
          className="form forgotPasswordForm d-block m-auto bg-white rounded boxShadow1"
          p={{ xs: 2, sm: 4 }}
          maxWidth="416px"
        >
          <Typography className="form-title text-center fw-medium m-0">
            Retrieve password
          </Typography>
          <Typography
            variant="body1"
            className="text-center"
            margin="5px 0 30px"
          >
            Follow these steps to recover your login credentials.
          </Typography>
          <Form
            initialValues={initialFormValue}
            onSubmit={saveEntity}
            render={({ handleSubmit, errors }) => (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (errors && Object.keys(errors).length) handleSubmit();
                }}
              >
                <>
                  <Box className="form-wrap">
                    <FormControl
                      variant="outlined"
                      className="field-wrap"
                      sx={{ mb: 2.5 }}
                    >
                      <Typography className="fw-bold" mb={0.75}>
                        Email address<span className="req">* </span>
                      </Typography>
                      <Field
                        render={({ input, meta }) => (
                          <TextField
                            autoComplete="off"
                            inputProps={{
                              autoCapitalize: 'none',
                            }}
                            type="text"
                            value={input.value}
                            onChange={input.onChange}
                            variant="outlined"
                            error={
                              isSubmit && meta.touched && meta.error
                                ? meta.error.isError
                                : false
                            }
                            helperText={
                              isSubmit && meta.touched && meta.error
                                ? meta.error.message
                                : ''
                            }
                          />
                        )}
                        name="email"
                        type="text"
                        validate={checkEmailValidity}
                      />
                    </FormControl>
                  </Box>
                  <Box
                    className="d-block d-sm-flex justify-content-end align-items-center text-center text-sm-left"
                    mb={{ xs: 1.5, sm: 0 }}
                    mt={{ xs: 1, sm: 2.5 }}
                  >
                    <Link
                      to={`/${ROUTE.LOGIN}`}
                      className="fw-semibold d-block mb-sm-0 mb-1 color-blue"
                    >
                      Return to log in
                    </Link>

                    <Button
                      variant="contained"
                      type="submit"
                      disabled={isLoading}
                      onClick={() => {
                        setIsSubmit(true);
                        handleSubmit();
                      }}
                      sx={{ marginLeft: { sm: 2, xs: 0 } }}
                    >
                      Retrieve password
                    </Button>
                  </Box>
                </>
              </form>
            )}
          />
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default ForgotPassword;
