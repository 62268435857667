const ServiceIcon3 = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.25 22.5H7.5C6.80965 22.5 6.25 23.0596 6.25 23.75C6.25 24.4404 6.80965 25 7.5 25H26.25V27.5H7.5C5.42894 27.5 3.75 25.8211 3.75 23.75V5C3.75 3.61929 4.86929 2.5 6.25 2.5H26.25V22.5ZM6.25 20.0625C6.45195 20.0215 6.66096 20 6.875 20H23.75V5H6.25V20.0625ZM20 11.25H10V8.75H20V11.25Z"
      fill="#075093"
    />
  </svg>
);

export default ServiceIcon3;
