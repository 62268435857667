import { Box, Button, Grid, Typography } from '@mui/material';
import { handleMenuLinkClick } from '../Utils/service';
import TopBanner from '../Assets/img/topBanner';
import TitleIcon from '../Assets/img/titleIcon';

const JobComponent = () => (
  <Box className="landing-page-container">
    <Grid container spacing={0} className="align-items-center">
      <Grid
        item
        xs={12}
        lg={6}
        className="d-flex justify-content-center justify-lg-content-end"
        pr={{ lg: 6.5, xs: 1.785 }}
        pl={1.875}
      >
        <Box
          className=""
          maxWidth={{ lg: 590, sm: '710px', xs: '100%' }}
          py={3.5}
        >
          <Box className="section-title d-flex align-items-start" mb={3.5}>
            <TitleIcon />
            <Typography className="fs-40 fw-bold color-black1" ml={2}>
              Simple, powerful checklists
            </Typography>
          </Box>

          <Typography className="fs-18 pera-text color-black1">
            Home Service Checklist is specifically designed to help every
            technician, from the greenest tech to seasoned veterans, perform at
            a level that has never been achieved before.
          </Typography>
          <Typography className="fs-18 pera-text color-black1">
            A simple-to-use tool unlocks fail-proof procedures necessary to
            assure unmatched technical accuracy, maximize every call, drive
            greater customer satisfaction, and generate higher profitability.
          </Typography>
          <Button
            variant="outlined"
            className="fw-normal d-block"
            sx={{
              padding: '7px 15.5px !important',
              marginTop: '28px',
              fontSize: '16px',
            }}
            onClick={() => {
              handleMenuLinkClick('contactUsSection');
            }}
          >
            Join the interest list
          </Button>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        className="job-image-wrap d-flex justify-content-center"
      >
        <TopBanner />
      </Grid>
    </Grid>
  </Box>
);

export default JobComponent;
