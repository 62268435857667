const ServiceIcon2 = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2134 3.75C10.7282 2.29351 12.1172 1.25 13.75 1.25H16.25C17.8827 1.25 19.2719 2.29351 19.7866 3.75H26.25C26.9404 3.75 27.5 4.30965 27.5 5V25C27.5 25.6904 26.9404 26.25 26.25 26.25H3.75C3.05965 26.25 2.5 25.6904 2.5 25V5C2.5 4.30965 3.05965 3.75 3.75 3.75H10.2134ZM5 6.25V23.75H25V6.25H19.7866C19.2719 7.70649 17.8827 8.75 16.25 8.75H13.75C12.1172 8.75 10.7282 7.70649 10.2134 6.25H5ZM13.75 3.75C13.0596 3.75 12.5 4.30965 12.5 5C12.5 5.69035 13.0596 6.25 13.75 6.25H16.25C16.9404 6.25 17.5 5.69035 17.5 5C17.5 4.30965 16.9404 3.75 16.25 3.75H13.75ZM12.5 11.25L18.75 15L12.5 18.75V11.25Z"
      fill="#075093"
    />
  </svg>
);

export default ServiceIcon2;
