/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  acceptInvitationApi,
  clearAcceptInvitaionState,
} from '../../Redux/Slice/authenticationSlice';
import Loader from '../CommonComponent/loader';
import {
  checkPasswordValidity,
  passwordEncrypt,
  userRoleRoute,
} from '../../Utils/service';
import Header from './header';
import { StoreState } from '../../Redux/Interface';
import { ResetPasswordFormValues } from './interface';
import { LocalStorage, ROUTE } from '../../Enum';

const AcceptInvitation = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordError, setPasswordError] = useState<string>('');
  const [isSubmit, setIsSubmit] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const inviteCode = queryParams.get('inviteCode');
  const dispatch = useDispatch();

  const [initialFormValue, setInitialFormValue] =
    useState<ResetPasswordFormValues>({
      password: '',
      confirmPassword: '',
    });

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };
  const { isLoading, data } = useSelector(
    (state: StoreState) => state.acceptInvitationState,
  );
  const { key } = useSelector((state: StoreState) => state.publicKey);
  const { isLoginPageVisible, isUserDataLoading, userData } = useSelector(
    (state: StoreState) => state.user,
  );

  const saveEntity = async (values: ResetPasswordFormValues) => {
    if (values.password !== values.confirmPassword) {
      setPasswordError("Password and Confirm Password doesn't match.");
    } else {
      setShowPassword(false);
      setShowConfirmPassword(false);
      dispatch(
        // @ts-expect-error Will fix this
        acceptInvitationApi({
          password: String(passwordEncrypt(key, values.password)),
          inviteCode: String(inviteCode),
        }),
      );
      setInitialFormValue({
        password: '',
        confirmPassword: '',
      });
    }
  };

  const redirectToLoginOrTemplate = () => {
    if (
      localStorage.getItem(LocalStorage.ACCESS_TOKEN) != null ||
      localStorage.getItem(LocalStorage.REFRESH_TOKEN) != null
    ) {
      if (!isUserDataLoading && !isLoginPageVisible && userData?.response) {
        window.open(userRoleRoute(userData?.response?.role), '_self');
      }
    } else {
      window.open(`${process.env.REACT_APP_URL}/${ROUTE.LOGIN}`, '_self');
    }
  };

  useEffect(() => {
    if (data) {
      if (data?.status === 200) {
        dispatch(clearAcceptInvitaionState());
        redirectToLoginOrTemplate();
      }
    }
  }, [data]);

  useEffect(() => {
    if (!inviteCode) {
      redirectToLoginOrTemplate();
      dispatch(clearAcceptInvitaionState());
    }
  }, [inviteCode]);

  return (
    <>
      <Loader loading={isLoading} />
      <Header />
      {/* <BackToLoginButton /> */}
      <Box className="authentication-form-wrap">
        <Form
          initialValues={initialFormValue}
          onSubmit={saveEntity}
          render={({ handleSubmit, errors }) => (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (errors && Object.keys(errors).length) handleSubmit();
              }}
            >
              <Box
                className="form loginForm d-block m-auto bg-white"
                p={{ xs: 2, sm: 3 }}
                maxWidth="416px"
              >
                <Typography className="form-title text-center fw-medium">
                  Set password
                </Typography>
                <Box className="form-wrap">
                  <FormControl
                    variant="outlined"
                    className="field-wrap"
                    sx={{ mb: 2.5 }}
                  >
                    <Typography className="fw-bold" mb={1}>
                      Password<span className="req">* </span>
                    </Typography>
                    <Field
                      render={({ input, meta }) => (
                        <TextField
                          id="password"
                          type={showPassword ? 'text' : 'password'}
                          sx={{
                            '& .Mui-error:before': {
                              background:
                                meta.error === 'passwordNotMatchError'
                                  ? 'none !important'
                                  : '',
                            },
                            '& .MuiFormHelperText-root': {
                              display:
                                meta.error === 'passwordNotMatchError'
                                  ? 'none !important'
                                  : '',
                            },
                          }}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {
                                  setShowPassword(!showPassword);
                                }}
                                edge="end"
                                className="gray-icon-btn"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            ),
                          }}
                          value={input.value}
                          onChange={input.onChange}
                          error={
                            isSubmit && meta.touched && meta.error
                              ? meta.error
                              : false
                          }
                          helperText={
                            isSubmit && meta.touched && meta.error
                              ? meta.error
                              : ''
                          }
                        />
                      )}
                      name="password"
                      type="text"
                      validate={(value, data: any) => {
                        if (!value) {
                          return 'Password is required.';
                        }
                        if (checkPasswordValidity(value)) {
                          return 'Must be 6-12 character (lowercase, uppercase, and 1 special character)';
                        }
                        if (
                          data.confirmPassword &&
                          data.confirmPassword !== data.password
                        ) {
                          return 'passwordNotMatchError';
                        }
                        return null;
                      }}
                    />
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    className="field-wrap"
                    sx={{ mb: { xs: 2, sm: 3 } }}
                  >
                    <Typography className="fw-bold" mb={1}>
                      Confirm Password<span className="req">* </span>
                    </Typography>
                    <Field
                      render={({ input, meta }) => (
                        <TextField
                          id="confirm-password"
                          type={showConfirmPassword ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {
                                  setShowConfirmPassword(!showConfirmPassword);
                                }}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                className="gray-icon-btn"
                              >
                                {showConfirmPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            ),
                          }}
                          value={input.value}
                          onChange={(value) => {
                            input.onChange(value);
                            setPasswordError('');
                          }}
                          error={
                            isSubmit && meta.touched && meta.error
                              ? meta.error
                              : false
                          }
                          helperText={
                            isSubmit && meta.touched && meta.error
                              ? meta.error
                              : ''
                          }
                        />
                      )}
                      name="confirmPassword"
                      type="text"
                      validate={(value, data: any) => {
                        if (!value) {
                          return 'Password is required.';
                        }
                        if (
                          data.confirmPassword &&
                          data.confirmPassword !== data.password
                        ) {
                          return "Password and Confirm Password doesn't match.";
                        }
                        return null;
                      }}
                    />
                  </FormControl>
                </Box>
                <Box
                  className="d-block d-sm-flex justify-content-end align-items-center text-center text-sm-left"
                  mb={{ xs: 1.5, sm: 0 }}
                  mt={1}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={isLoading}
                    onClick={() => {
                      handleSubmit();
                      setIsSubmit(true);
                    }}
                  >
                    Set password
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        />
      </Box>
    </>
  );
};

export default AcceptInvitation;
