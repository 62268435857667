const TitleIcon = () => (
  <svg
    width="42"
    height="40"
    viewBox="0 0 42 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_462_108)">
      <path
        d="M33.4191 20.8943V35.4145H4.19464V14.2046L18.8069 4.96388L28.521 11.1079L31.5617 8.06713L18.8069 0L0 11.8969V39.6091H37.6104V16.703L33.4191 20.8943Z"
        fill="#265EAC"
      />
      <path
        d="M40.7366 5.77212C39.4447 4.70703 37.4558 4.85167 36.2066 6.10086L19.1881 23.1227L17.1434 21.078C17.62 19.4244 17.1861 17.7808 15.8975 16.4921C14.3951 14.9898 12.4129 14.6512 10.4898 15.5585C10.2136 15.69 10.1446 16.0516 10.3583 16.2653L13.0999 19.0069C13.2183 19.1253 13.2643 19.2995 13.2084 19.454C13.0342 19.9208 12.7449 20.3843 12.3044 20.8216C11.8343 21.2917 11.3346 21.6039 10.835 21.7716C10.6837 21.8242 10.5128 21.7716 10.401 21.6598L7.70541 18.9642C7.48844 18.7473 7.12026 18.8196 6.99534 19.1023C6.14392 20.9925 6.49896 22.9353 7.97826 24.4146C9.23731 25.6737 10.8317 26.1142 12.4425 25.6934L16.8606 30.1116C17.482 30.7362 18.3104 31.0781 19.1914 31.0781C20.0724 31.0781 20.9008 30.7362 21.5221 30.1116L40.9831 10.6538C41.6373 9.99964 41.9891 9.09234 41.943 8.16531C41.897 7.22513 41.4697 6.37699 40.7366 5.77212ZM39.5268 9.20082C39.0403 9.68734 38.2546 9.68734 37.7681 9.20082C37.2816 8.71429 37.2816 7.92862 37.7681 7.44209C38.2546 6.95557 39.0403 6.95557 39.5268 7.44209C40.0134 7.92862 40.0134 8.71429 39.5268 9.20082Z"
        fill="#5EAEE0"
      />
      <path
        d="M16.0191 26.2913L19.1881 23.1223L17.1434 21.0776C17.62 19.4241 17.1861 17.7804 15.8975 16.4918C14.3951 14.9895 12.4129 14.6509 10.4898 15.5582C10.2136 15.6897 10.1446 16.0513 10.3583 16.265L13.0999 19.0066C13.2183 19.1249 13.2643 19.2992 13.2084 19.4537C13.0342 19.9205 12.7449 20.384 12.3044 20.8212C11.8343 21.2913 11.3346 21.6036 10.835 21.7713C10.6837 21.8238 10.5128 21.7713 10.401 21.6595L7.70541 18.9639C7.48844 18.7469 7.12026 18.8192 6.99534 19.1019C6.14392 20.9922 6.49896 22.935 7.97826 24.4143C9.23731 25.6733 10.8317 26.1138 12.4425 25.693L13.0408 26.2913C13.8593 27.1132 15.1973 27.1132 16.0191 26.2913Z"
        fill="url(#paint0_linear_462_108)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_462_108"
        x1="8.74523"
        y1="17.2635"
        x2="16.896"
        y2="25.4142"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.2514" stopColor="#5EAEE0" />
        <stop offset="0.3837" stopColor="#5BAADE" />
        <stop offset="0.549" stopColor="#549FD7" />
        <stop offset="0.7321" stopColor="#498ECC" />
        <stop offset="0.9266" stopColor="#3978BD" />
        <stop offset="1" stopColor="#3170B7" />
      </linearGradient>
      <clipPath id="clip0_462_108">
        <rect width="42" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default TitleIcon;
