import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useNavigate, Link } from 'react-router-dom';
import { LocalStorage, ROUTE } from '../../Enum';
// import FormTopImg from '../../Assets/img/form-img.jpg';
import { passwordEncrypt, userRoleRoute } from '../../Utils/service';
import {
  clearState,
  getCurrentUser,
  userLogin,
} from '../../Redux/Slice/authenticationSlice';
import Loader from '../CommonComponent/loader';
import Footer from './footer';
// import Header from './header';
import { StoreState } from '../../Redux/Interface';
import { LoginFormValues } from './interface';
import { checkEmailValidity } from '../AdminSrc/Utils/service';
import LogInLogo from '../../Assets/img/loginLogo';

const LoginPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmit, setSubmit] = useState(false);
  const dispatch = useDispatch();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const navigation = useNavigate();
  const publicKey = useSelector((state: StoreState) => state.publicKey);

  const [initialFormValue, setInitialFormValue] = useState<LoginFormValues>({
    email: '',
    password: '',
    rememberMe: true,
  });

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const { isLoading, data } = useSelector(
    (state: StoreState) => state.authentication,
  );

  const saveEntity = async (values: LoginFormValues) => {
    setShowPassword(false);
    dispatch(
      // @ts-expect-error Will fix this
      userLogin({
        ...values,
        email: values?.email?.trim(),
        password: passwordEncrypt(publicKey.key, values.password),
      }),
    );
    setInitialFormValue(values);
  };

  useEffect(() => {
    if (data !== null) {
      // @ts-expect-error Will fix this
      dispatch(getCurrentUser());
      if (data?.response?.emailVerified) {
        window.open(`${userRoleRoute(data.response.role)}`, '_self');
      }
      if (!data?.response?.emailVerified) {
        navigation(`/${ROUTE.ACCOUNT_VERIFICATION}`, {
          state: { email: initialFormValue?.email?.trim() },
        });
      }
    }
  }, [data]);

  const { isLoginPageVisible, isUserDataLoading, userData } = useSelector(
    (state: StoreState) => state.user,
  );

  useEffect(() => {
    if (
      localStorage.getItem(LocalStorage.ACCESS_TOKEN) != null ||
      localStorage.getItem(LocalStorage.REFRESH_TOKEN) != null
    ) {
      if (!isUserDataLoading && !isLoginPageVisible && userData?.response) {
        window.open(`${userRoleRoute(userData?.response?.role)}`, '_self');
      }
    } else {
      navigation(`/${ROUTE.LOGIN}`);
    }
  }, [isLoginPageVisible, isUserDataLoading, userData]);

  return (
    <>
      <Loader loading={isLoading || isUserDataLoading} />
      {!isUserDataLoading && isLoginPageVisible && (
        <>
          <Box className="authentication-form-wrap" mt="60px">
            <Box className="m-auto" mb={2} maxWidth={250}>
              <LogInLogo />
            </Box>
            <Form
              initialValues={initialFormValue}
              onSubmit={saveEntity}
              render={({ handleSubmit, errors }) => (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (errors && Object.keys(errors).length) handleSubmit();
                  }}
                >
                  <Box
                    className="form loginForm d-block m-auto bg-white rounded boxShadow1"
                    p={{ xs: 2, sm: 4 }}
                    maxWidth="416px"
                  >
                    <Typography className="form-title text-center fw-medium">
                      Welcome back!
                    </Typography>
                    <Box className="form-wrap">
                      <FormControl
                        variant="outlined"
                        className="field-wrap"
                        sx={{ mb: { xs: 2.5, sm: 4 } }}
                      >
                        <Typography className="fw-bold" mb={1}>
                          Username<span className="req">* </span>
                        </Typography>
                        <Field
                          render={({ input, meta }) => (
                            <TextField
                              autoComplete="off"
                              inputProps={{
                                autoCapitalize: 'none',
                              }}
                              type="text"
                              value={input.value}
                              onChange={input.onChange}
                              variant="outlined"
                              error={
                                isSubmit && meta.touched && meta.error
                                  ? meta.error
                                  : false
                              }
                              helperText={
                                isSubmit && meta.touched && meta.error
                                  ? meta.error
                                  : ''
                              }
                            />
                          )}
                          name="email"
                          type="text"
                          validate={checkEmailValidity}
                        />
                      </FormControl>

                      <FormControl
                        variant="outlined"
                        className="field-wrap"
                        sx={{ mb: { xs: 2.5, sm: 4 } }}
                      >
                        <Typography className="fw-bold" mb={1}>
                          Password<span className="req">* </span>
                          <span>
                            <Link
                              to={`/${ROUTE.FORGOT_PASSWORD}`}
                              className="fw-semibold ml-1 color-blue"
                              onClick={() => {
                                dispatch(clearState());
                              }}
                            >
                              Forgot password?
                            </Link>
                          </span>
                        </Typography>
                        <Field
                          render={({ input, meta }) => (
                            <TextField
                              id="outlined-adornment-password"
                              type={showPassword ? 'text' : 'password'}
                              autoComplete="off"
                              InputProps={{
                                endAdornment: (
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    className="gray-icon-btn"
                                  >
                                    {showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                ),
                              }}
                              value={input.value}
                              onChange={input.onChange}
                              error={
                                isSubmit && meta.touched && meta.error
                                  ? meta.error.isError
                                  : false
                              }
                              helperText={
                                isSubmit && meta.touched && meta.error
                                  ? meta.error.message
                                  : ''
                              }
                            />
                          )}
                          name="password"
                          type="text"
                          validate={(values) => {
                            if (values?.toString()?.trim().length > 0) {
                              return undefined;
                            }
                            return {
                              isError: true,
                              message: 'Password is required.',
                            };
                          }}
                        />
                      </FormControl>
                      <Field
                        render={({ input }) => (
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Remember me"
                            sx={{ margin: '-8px 0 24px -5px' }}
                            onChange={input.onChange}
                            checked={input.value}
                          />
                        )}
                        name="rememberMe"
                        type="text"
                      />
                    </Box>
                    <Box
                      className="d-block d-sm-flex justify-content-end align-items-center text-center text-sm-left"
                      mb={{ xs: 1.5, sm: 0 }}
                    >
                      <Button
                        variant="contained"
                        type="submit"
                        disabled={isLoading}
                        onClick={() => {
                          setSubmit(true);
                          handleSubmit();
                        }}
                        sx={{ padding: '9.5px 20px !important' }}
                      >
                        Log in
                      </Button>
                    </Box>
                  </Box>
                </form>
              )}
            />
          </Box>
          <Footer />
        </>
      )}
    </>
  );
};

export default LoginPage;
