const ServiceIcon1 = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25 2.5C25.6904 2.5 26.25 3.05965 26.25 3.75V10C26.25 10.6903 25.6904 11.25 25 11.25H18.75V27.5C18.75 28.1904 18.1904 28.75 17.5 28.75H12.5C11.8097 28.75 11.25 28.1904 11.25 27.5V11.25H4.375C3.68465 11.25 3.125 10.6903 3.125 10V7.02254C3.125 6.54908 3.3925 6.11625 3.81599 5.90451L10.625 2.5H25ZM18.75 5H11.2152L5.625 7.79509V8.75H13.75V26.25H16.25V8.75H18.75V5ZM23.75 5H21.25V8.75H23.75V5Z"
      fill="#075093"
    />
  </svg>
);

export default ServiceIcon1;
